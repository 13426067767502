@import "var";

// main-visual---------------------------------------------------------
.main-visual {
	width: 100%;
	height: 770px;
	padding-top: 318px;
	box-sizing: border-box;
	background: #000 url(../../assets/img/index/main.jpg) no-repeat center top;
	
	&_catch {
		font-weight: 600;
		font-size: 80px;
		@include font-jp;
		color: #fff;
		text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.29);
		margin-bottom: 27px;
		line-height: 1;
	}
	
	&_catch-sub {
		font-weight: bold;
		font-size: 20px;
		@include font-jp;
		color: #fff;
		margin-bottom: 50px;
	}
	
	&_btn {
		a {
			display: block;
			border: 1px solid #fff;
			width: 220px;
			height: 48px;
			box-sizing: border-box;
			line-height: 48px;
			font-size: 17px;
			font-weight: bold;
			margin: 0 auto;
			color: #fff;
			text-decoration: none;
			background: none;
			@include transition(.15);
			
			&:hover {
				color: #000;
				background-color: #fff;
			}
		}
	}
	
	@include max_screen($bp_sp) {
		height: 500px;
		background-size: cover;
		padding-top: 140px;
		
		&_catch {
			font-size: 60px;
		}
		
		&_catch-sub {
			font-size: 18px;
		}
		
		&_btn {
			a {
				width: 190px;
				height: 48px;
				font-size: 15px;
			}
		}
	}
}


// business ---------------------------------------------------------
.business {
	width: 100%;
	overflow: hidden;
	
	&_left-col {
		width: 45.5%;
		float: left;
		min-width: 436px;
		padding-top: 150px;
		height: 540px;
		box-sizing: border-box;
		overflow: hidden;
		
		&_inner {
			width: 436px;
			padding-right: 60px;
			box-sizing: border-box;
			float: right;
			text-align: left;
		}
	
		@include max_screen($bp_sp) {
			width: 100%;
			height: auto;
			float: none;
			min-width: 0;
			padding: 40px 20px;
			
			&_inner {
				width: 100%;
				padding: 0;
				float: none;
			}
		}
	}
	
	&_right-col {
		width: 54.5%;
		height: 540px;
		float: left;
		background: url(../../assets/img/index/photo1.jpg) no-repeat;
		background-size: cover;
		
		@include max_screen($bp_sp) {
			display: none;
		}
	}
	
	&_bottom {
		width: 100%;
		height: 520px;
		background: url(../../assets/img/index/photo2.jpg) no-repeat;
		background-size: cover;
		clear: both;
		
		@include max_screen($bp_sp) {
			display: none;
		}
	}
	
	&_ttl {
		color: #0068b7;
		font-weight: bold;
		font-size: 27px;
		margin-bottom: 38px;
		
		span {
			font-weight: normal;
			font-size: 10px;
			vertical-align: middle;
			padding-left: 20px;
			top: -3px;
			position: relative;
			display: inline-block;
		}
	}
	
	&_desc {
		line-height: 2;
		margin-bottom: 38px;
	}
	
	&_btn {
		a {
			display: inline-block;
			border: 1px solid #000;
			box-sizing: border-box;
			font-size: 14px;
			padding:  10px 20px;
			font-weight: bold;
			margin: 0 auto;
			color: #000;
			text-decoration: none;
			background: none;
			@include transition(.15);
			
			span {
				display: inline-block;
				padding: 0 32px 0 0;
				position: relative;
				
				&:after {
					content: "";
					width: 17px;
					height: 7px;
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -5px;
					background: url(../../assets/img/common/btn_arw_black.png) no-repeat;
				}
			}
			
			&:hover {
				color: #fff;
				background: #000;
				
				span:after {
					background: url(../../assets/img/common/btn_arw_white.png) no-repeat;
				}
			}
		}
	}
}


// recruit ---------------------------------------------------------
.recruit {
	width: 100%;
	padding: 100px 0;
	background: url(../../assets/img/index/recruit_photo.jpg) no-repeat;
	background-size: cover;
	
	&_inner {
		width: 980px;
		margin: 0 auto;
		padding: 70px 0 80px;
		background: rgba(0, 73, 134, 0.831);
		color: #fff;
	}
	
	&_ttl {
		font-weight: bold;
		font-size: 29px;
		margin-bottom: 55px;
		
		span {
			display: block;
			font-weight: normal;
			font-size: 10px;
			padding-top: 10px;
		}
	}
	
	&_msg {
		font-size: 18px;
		line-height: 2.2;
		font-weight: bold;
		letter-spacing: 1.4;
		margin-bottom: 60px;
	}
	
	@include max_screen($bp_sp) {
		padding: 40px 20px;
		box-sizing: border-box;
		
		&_inner {
			width: 100%;
			padding: 40px 20px;
			box-sizing: border-box;
		}
		
		&_ttl {
			margin-bottom: 30px;
		}
		
		&_msg {
			font-size: 15px;
			margin-bottom: 30px;
		}
	}
	
	&_btn {
		a {
			display: inline-block;
			border: 1px solid #fff;
			box-sizing: border-box;
			font-size: 14px;
			padding:  10px 20px;
			font-weight: bold;
			margin: 0 auto;
			color: #fff;
			text-decoration: none;
			background: none;
			@include transition(.15);
			
			span {
				display: inline-block;
				padding: 0 32px 0 0;
				position: relative;
				
				&:after {
					content: "";
					width: 17px;
					height: 7px;
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -5px;
					background: url(../../assets/img/common/btn_arw_white.png) no-repeat;
				}
			}
			
			&:hover {
				color: #000;
				background: #fff;
				
				span:after {
					background: url(../../assets/img/common/btn_arw_black.png) no-repeat;
				}
			}
		}
	}
}