$bp_tab: 959px;
$bp_sp: 767px;

// ---------------------------------
@mixin txt_link_color {
	a,
	a:link,
	a:visited {
		color: #0068b7;
		text-decoration: underline;
	}
	
	a:hover {
		text-decoration: none;
	}
}

@mixin btn_link_color {
	a,
	a:link,
	a:visited {
		color: #333;
		background-color: #fff;
		text-decoration: none;
		@include transition(.2, background-color);
	}
	
	a:hover {
		background-color: #fff;
	}
}

@mixin font_jp {
	font-family: "Yu Mincho", "YuMincho", serif;
}

@mixin cf {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin placeholderColor($color) {
	&:placeholder-shown {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}

@mixin transition($time:.3, $prop:all) {
	-moz-transition: $prop #{$time}s ease-in-out;
	-webkit-transition: $prop #{$time}s ease-in-out;
	transition: $prop #{$time}s ease-in-out;
}

@mixin max_screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}

@mixin is($device) {
	@at-root.is_$device & {
		@content;
	}
}